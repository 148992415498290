<template>
  <UserTop :title="title" @userInfo="getUserInfo">
    <div class="right-box">

      <div class="wallet-box">
        <div class="wallet-title">
          <i class="el-icon-wallet"></i>
<!--          <img src="@/assets/images/icon/balance.png" alt="">-->
          <span>我的智慧豆</span>
        </div>
        <div class="wallet-info">
          <div class="num-box">
            <span class="t">剩余智慧豆</span>
            <span class="n">{{userSetInfo.balance}}</span>
          </div>
          <div class="btn" @click="openRecharge">去充值</div>
        </div>
        <div class="wallet-tabs">
          <div class="tabs-box">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="消费订单" name="shareOrder">
                <div class="tabs-item" v-loading="loading">
                  <el-table :data="selectlist" stripe v-if="selectlist && selectlist.length > 0">
                    <el-table-column label="订单号" align="left" width="200" prop="id"></el-table-column>
                    <el-table-column label="标题" align="left" prop="title" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column label="现金价格" align="left" width="120">
                      <template slot-scope="scope">
                        {{ scope.row.price === 0 ? '' : scope.row.price }}
                      </template>
                    </el-table-column>
                    <el-table-column label="智慧豆" align="left" width="120">
                      <template slot-scope="scope">
                        {{ scope.row.xyDou === 0 ? '' : scope.row.xyDou }}
                      </template>
                    </el-table-column>
                    <el-table-column label="支付平台" align="left" width="120">
                      <template slot-scope="scope">
                        <el-tag v-if="scope.row.platform === 1">{{ platforms[scope.row.platform] }}</el-tag>
                        <el-tag v-else-if="scope.row.platform === 2" type="success">{{ platforms[scope.row.platform] }}</el-tag>
                        <el-tag v-else-if="scope.row.platform === 3" type="warning">{{ platforms[scope.row.platform] }}</el-tag>
                      </template>
                    </el-table-column>
                    <el-table-column label="订单类型" align="left" width="160">
                      <template slot-scope="scope">
                        {{ types[scope.row.type] }}
                      </template>
                    </el-table-column>
                    <el-table-column label="时间" align="left" width="160" prop="createTime"></el-table-column>
                  </el-table>
                  <el-empty v-else :image-size="100" description="暂无数据"></el-empty>
                </div>
              </el-tab-pane>
              <el-tab-pane label="充值记录" name="payPage">
                <div class="tabs-item" v-loading="loading">
                  <el-table :data="selectlist" stripe v-if="selectlist && selectlist.length > 0">
                    <el-table-column label="订单号" align="left" width="200" prop="orderId"></el-table-column>
                    <el-table-column label="标题" align="left" prop="title" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column label="充值金额" align="left" width="120" prop="price"></el-table-column>
                    <el-table-column label="支付平台" align="left" width="120">
                      <template slot-scope="scope">
                        <el-tag type="warning">{{ platforms[scope.row.platform] }}</el-tag>
                      </template>
                    </el-table-column>
                    <el-table-column label="描述" align="left" prop="description" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column label="时间" align="left" width="160" prop="createTime"></el-table-column>
                  </el-table>
                  <el-empty v-else :image-size="100" description="暂无数据"></el-empty>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="pagination" v-if="total && total > 0">
            <el-pagination class="pc-show" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                           :current-page="queryInfo.pageNum" :page-sizes="[10, 20, 30]" :page-size="queryInfo.pageSize"
                           layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>

            <el-pagination class="h5-show" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                           :current-page="queryInfo.pageNum" :page-size="queryInfo.pageSize"
                           layout="total, prev, pager, next" :total="total">
            </el-pagination>
          </div>
        </div>
      </div>

      <WalletBalance ref="Wallet" @userSetInfo="getUserSetInfo"></WalletBalance>

    </div>
  </UserTop>
</template>
<script>
import XydouApi from "@/api/xydou"
import UserPromotApi from "@/api/user"
import UserTop from "@/views/user_promot/components/userTop"
import WalletBalance from '@/components/walletBalance'
export default {
  name: 'wallet',
  components: {
    UserTop,
    WalletBalance
  },
  data() {
    return {
      title: '我的钱包',
      userInfo: {
        isLogin: false, // 用户登录状态
        username: '',
        nickname: '',
        token: ''
      },
      userSetInfo: '',
      platforms: ['','支付宝','微信','余额支付'],
      types: ['组稿订单','案例库订单','PPT模板订单','推广员付费升级订单','智慧豆充值订单','会员充值订单'],
      queryInfo: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      selectlist: [],
      selectIndex: 0,
      activeName: 'shareOrder',
      loading: false,
      balanceList: [],
      balanceDialogVisible: false
    }
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    // console.log(this.userInfo)
    this.getUserDetailVo()
    this.postUserOrder()
  },
  methods: {
    getUserSetInfo(args){
      if(args === true){
        this.getUserDetailVo()
      }
    },
    getUserDetailVo(){
      UserPromotApi.getUserDetailVo(this.userInfo.token).then(res => {
        this.userSetInfo = res.data
        localStorage.setItem('userSetInfo', JSON.stringify(res.data))
      }).catch(() => {
      })
    },
    getUserInfo(msg){
      if (msg && msg.user === false){
        this.userInfo = this.$options.data().userInfo
        localStorage.removeItem('userInfo')
      }else {
        this.userInfo = msg.info
      }
    },
    handleClick(tab) {
      // console.log(tab.index);
      this.loading = true
      this.selectIndex = Number(tab.index)
      this.queryInfo = this.$options.data().queryInfo
      if(this.selectIndex === 0){
        this.postUserOrder()
      }else if(this.selectIndex === 1){
        this.postWalletLog()
      }
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      if(this.selectIndex === 0){
        this.postUserOrder()
      }else if(this.selectIndex === 1){
        this.postWalletLog()
      }
    },
    handleCurrentChange(newNum) {
      this.queryInfo.pageNum = newNum
      if(this.selectIndex === 0){
        this.postUserOrder()
      }else if(this.selectIndex === 1){
        this.postWalletLog()
      }
    },
    // 去充值
    openRecharge(){
      this.$refs.Wallet.getXydouList(this.userInfo.token,this.userSetInfo.balance)
    },
    // 查询 我的消费订单
    postUserOrder(){
      let _this = this
      UserPromotApi.postUserOrder(this.queryInfo,this.userInfo.token).then(res => {
        // console.log(res.data)
        _this.selectlist = res.data.data
        _this.total = res.data.total
        _this.loading = false
      })
    },
    // 查询 我的充值记录
    postWalletLog(){
      let _this = this
      UserPromotApi.postWalletLog(this.queryInfo,this.userInfo.token).then(res => {
        // console.log(res.data)
        _this.selectlist = res.data.data
        _this.total = res.data.total
        _this.loading = false
      })
    }
  }
}
</script>
<style scoped lang="less">
.right-box{
  width: calc(100% - 200px);
  .wallet-box{
    border-radius: 15px;
    border: 2px solid #ffffff;
    background-color: rgba(255,255,255,0.3);
    .wallet-title{
      padding: 20px 0 0 20px;
      span{
        margin-left: 10px;
        font-size: 14px;
        font-weight: bold;
      }
      i{
        font-size: 16px;
      }
    }
    .wallet-info{
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: center;
      .num-box{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span{
          height: 30px;
        }
        span.t{
          color: #686868;
        }
        span.n{
          font-size: 30px;
          color: #ee6360;
        }
      }
      .btn{
        margin-left: 50px;
        width: 80px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        border-radius: 5px;
        background-image: linear-gradient(90deg,#409EFF,#6e83f7);
        box-shadow: 0 2px 5px rgba(81, 59, 248, 0.3);
        color: #ffffff;
        cursor: pointer;
      }
    }
    .wallet-tabs{
      .tabs-box{
        /deep/ .el-tabs__header {
          padding: 10px 20px;
          .el-tabs__nav-wrap::after{
            background-color: transparent !important;
          }
        }
        .tabs-item{
          padding: 0 10px;
          /deep/ .el-table {
            background-color: transparent !important;
            thead{
              color: #3c3c3c !important;
            }
            th {
              background-color: transparent !important;
              border-bottom: none !important;
            }
            tr {
              background-color: transparent !important;
              td.el-table__cell{
                border-bottom: 1px solid rgba(59, 125, 248, 0.1) !important;
                background-color: transparent !important;
              }
            }
          }
          /deep/ .el-table::before{
            background-color: transparent !important;
          }
        }
      }
      .pagination{
        padding-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .pc-show{
          display: block;
        }
        .h5-show{
          display: none;
        }
      }
    }
  }
}
@media screen and (max-width: 900px){
  .right-box {
    width: 100%;
    .wallet-box{
      .wallet-info{
        padding: 20px 0;
      }
      .wallet-tabs{
        .pagination{
          padding-bottom: 20px;
          .pc-show{
            display: none;
          }
          .h5-show{
            display: block;
          }
        }
      }
    }
  }
}
</style>
